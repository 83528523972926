<template>
    <CommonCard style="padding: 5%;">
        <v-row class="mt-1" style="padding: 20px">
            <v-toolbar>
              <v-toolbar-title>
                <h3>Recicle leads</h3>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn @click="goBack()" color="error"> 
                <v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>Regresar
              </v-btn>
            </v-toolbar>
        </v-row> 
        <filtros-recicle 
          @filters="getLeads"
          :campanias="campanias"
          :cleanFiltersflag="cleanFiltersflag">
        </filtros-recicle>
        <tabla-recicle
          @cleanFilters="cleanFilters"
          :loading="loading"
          :campanias="campanias"
          :leads="leads">
        </tabla-recicle>
    </CommonCard>
</template>
 
<script>
import { mainAxios } from "../../../mainAxios";
import moment from "moment";
import FiltrosRecicle from "./FiltrosRecicle.vue"
import TablaRecicle from "./TablaRecicle.vue"
import CommonCard from "../../../components/commonComponents/CommonCard.vue";

export default {
  components: {
    FiltrosRecicle,
    TablaRecicle,
    CommonCard
  },

  data() {
    return {
      loading: false,
      leads: [],
      campanias: [],
      menssage: '',
      cleanFiltersflag: false
    };
  },
  mounted() {
    this.getCampanias()
  },
  computed: {
    widthLogoSeg() {
      if (this.$vuetify.breakpoint.smAndDown) return "50";
      if (this.$vuetify.breakpoint.mdAndDown) return "50";
      return "80";
    },
  },

  methods: {
    getLeads(filtros){
      this.loading = true;
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.agenteAccessToken,
            }
        }
        let value = JSON.stringify(filtros)
        value = JSON.parse(value)
        mainAxios.post('/v1/origenLead/list/filtros',value,config).then(response => {
          this.leads = []
            response.data.map(element =>{
              const jsonFormat = JSON.parse(element.request_json)
              this.leads.push({
                id: element.id ? element.id : 'No disponible',
                uuid: element.uuid ? element.uuid : 'No disponible',
                recicle: false,
                status: element.status ? element.status : 'No disponible',
                fecha_lead: element.fecha_lead ? moment.utc(element.fecha_lead).format("DD/MM/YYYY") : 'No disponible',
                telefono: element.telefono ? element.telefono : 'No disponible',
                nombreCompleto: (jsonFormat.nombre ? jsonFormat.nombre : '' +
                                jsonFormat.apellidoPaterno ? jsonFormat.apellidoPaterno : '' +
                                jsonFormat.apellidoMaterno ? jsonFormat.apellidoMaterno : '' ),
                canal: element.canal ? element.canal : 'No disponible',
                ramo: element.ramo ? element.ramo : 'No disponible',
                campaña: element.campania_id ? element.campania_id : 'No disponible',
                ulmimaLlamada: element.fecha_ultima_llamada ? moment.utc(element.fecha_ultima_llamada).format("DD/MM/YYYY") : 'No disponible',
                intentos: element.intentos ? element.intentos : 'No disponible',
                operador: element.nombreOperador ? element.operador : 'No disponible',
              });
              this.loading = false;
            })
           
            if(this.leads.length <= 0){
              this.menssage = 'No se encontraron leds...'
                  this.$toast.info(this.menssage, {
                  position: "top-right",
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false
              });
            }
          this.loading = false;

        })     
    }, 
    getCampanias() {
        const config = {
        headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
        };
        mainAxios
        .get("/v1/campania/lead-segurify/list", config)
        .then((response) => {
            this.campanias = response.data;
        });
    },
    cleanFilters(){
      this.leads = [];
      this.cleanFiltersflag = true;
      this.cleanFiltersflag = false;
    }
  },
};
</script>
